import getData from "@/__main__/get-data.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import noopModel from "@/data-models/no-op.mjs";
import * as API from "@/game-tft/api.mjs";

export default async function fetchData(
  [region, name],
  urlSearchParams,
  state,
) {
  if (urlSearchParams.has("queryLeagueProfile")) {
    // trigger backend to scrape profile and gather missing profileIconId
    await getData(API.getLeagueProfile(region, name), noopModel, undefined);
    urlSearchParams.delete("queryLeagueProfile");
    updateRoute(`/tft/profile/${region}/${name}`, urlSearchParams, state);
  }
}
